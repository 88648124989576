/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
  --primary-blue-color: #13284c;
}

// alert custom css
.my-alert-css {
  --background: #ffffff;
  --max-height: 500px;
  --max-width: 510px;

  .alert-title,
  .alert-sub-title {
    color: #13284c;
    font-weight: 500;
  }

  .alert-wrapper {
    position: relative;
  }

  .alert-button {
    position: absolute;
    top: 20px;
    right: 23px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #13284c;

    .alert-button-inner {
      background: #ffffff;
    }
  }

  .alert-message {
    max-height: 100%;
    padding: 0px 23px;
    color: #13284c;
    font-size: 15px;

    div {
      list-style-type: none;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;

      li {
        border-radius: 50px;
        font-size: 13px;
        min-width: 39px;
        min-height: 18px;
        color: #13284c;
        background-color: #ced1d4;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
      }
    }

    strong {
      margin: 0 10px;
    }

    p {
      color: #13284c;

      strong {
        margin-left: 0px;
        margin-right: 15px;
        font-size: 12px;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0px;

      li {
        margin-bottom: 5px;
        color: #13284c;
      }
    }
  }
}

// @receiver info alert css
.my-alert-css-receiver {
  --background: #ffffff;
  --max-height: 500px;
  --max-width: 80vw;

  .alert-title {
    border-bottom: 1px solid #CED1D4;
    color: #13284c;
    font-weight: 700;
    margin-right: 4px;
    font-size: 16px;
    padding-bottom: 4px;
  }

  .alert-sub-title {
    color: #13284c;
    font-weight: 500;
    font-size: 14px;
  }

  .alert-wrapper {
    position: relative;
  }

  .alert-button {
    position: absolute;
    top: 20px;
    right: 23px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #13284c;

    .alert-button-inner {
      background: #ffffff;
    }
  }

  .alert-message {
    max-height: 100%;
    padding: 0px 23px;

    div {
      list-style-type: none;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;

      li {
        border-radius: 50px;
        font-size: 13px;
        min-width: 39px;
        min-height: 18px;
        color: #13284c;
        background-color: #ced1d4;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
      }
    }

    strong {
      margin: 0 10px;
    }

    p {
      color: #13284c;
      font-size: 13px;

      strong {
        margin-left: 0px;
        margin-right: 15px;
      }
    }
  }
}

// @ user menu dropdown css
ion-popover {
  --height: auto;
  ion-item.select-interface-option{
    --background: #ffffff;
    background:#ffffff;
    color:#13284c;
    --color:#13284c;
  ion-list.list-md{
    --padding:0;
    --padding-top:0 !important;
    padding-bottom:0 !important;
  }
    
  }
  
  ion-item {
    color: #13284c;
    --background: #ffffff;
    border: 2px solid #CED1D4;
    transition: all ease-in 200ms;

    ion-label {
      text-align: center;
    }
  }

  ion-item:hover {
    border-color: #13284c;
  }
}
    

// @ receiver success alert css
ion-alert.my-confirm-alert {
  --background: #ffffff;
  --max-height: 500px;
  --max-width: 510px;

  .alert-title {
    color: #B1102B;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }

  .alert-message {
    color: #13284c;
    font-weight: 500;
  }

  .alert-button {
    color: #13284c;
    font-weight: 700;
    text-transform: none;
    font-size: 20px;
    background: #FFFFFFFF;

    .alert-button-inner {
      background: #ffffff;
    }
  }
}

ion-alert.my-confirm-alert.ion-text-center{
  --max-height: 700px;
  .alert-title {
    text-align: center;
  }
  strong {
    margin: 0;
  }
  
  input {
    border-color: #B1102B;
    color: #13284c;
    padding: 0;
    font-size: 16px;
  }

  input:focus {
    border-color: #13284c;
  }

  .alert-title {
    font-weight: 700;
    font-size: 24px;
    height:20px;
    width:100%;
    text-align:center;
  }
  
  .alert-input-group{
    padding-bottom:0;
  }
  
  .alert-message {
    margin: 35px;
    margin-bottom:0px;
  }

  .alert-button{
    text-transform: none;
    position: static;
    width: 200px;
    height: 50px;
    font-family: unset;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
  }

  .alert-button-inner {
    background: #FFFFFF;
    border: 2px solid #13284c;
    justify-content: center;
    height: 32px;
  }

  .alert-button-group {
    justify-content: center;
  }

  .alert-button:active .alert-button-inner {
    background: #13284c;
  }

  .alert-button:active{
    color: #FFFFFF;
  }
  
  .alert-button.x-button:active span{
    color:#FFFFFF;
  }
  }

.alert-button.ion-focused {
  background: #FFFFFF !important;
}

ion-toast.my-toast-css, ion-toast.my-login-toast-css {
  color: #ffffff;
  --background: #007934;
  height:calc(100vh - 72px);
}

ion-toast.my-error-toast-css {
  color: #ffffff;
  --background: #B1102B;
  height:calc(100vh - 72px);
}

ion-toast.my-toast-css button.toast-button {
  color: #ffffff;
  background: #ffffff;
}

// @discover tab alert css
.my-alert-css-receiver-discover {
  --background: #ffffff;
  --max-height: 500px;
  --max-width: 80vw;

  .alert-button-inner {
    background: #ffffff;
  }

  .alert-button.secondary {
    text-style: none;
  }

  .alert-button {
    font-size: 16px;
    color: #13284c;
    text-transform: none;
    font-weight: 600;
    margin-top: 20px;
  }

  .alert-button.cancel-btn {
    color: #B1102B;
  }

  .alert-message {
    max-height: 100%;
    padding: 0px 23px;
    color: #13284c;
    font-size: 14px;
    text-align: center;
  }
}

//@ verification alert css
.my-alert-css.my-alert-verification-css {
  --max-width: 90vw;
  strong {
    margin: 0;
  }
  
  input {
    border-color: #B1102B;
    color: #13284c;
    padding: 0;
    font-size: 40px;
  }

  input:focus {
    border-color: #13284c;
  }

  .alert-title {
    font-weight: 700;
    font-size: 24px;
    height:20px;
    width:100%;
    text-align:center;
  }

  .alert-message {
    margin: 35px;
  }

  .alert-button{
    text-transform: none;
    position: static;
    width: 200px;
    height: 32px;
    font-family: unset;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
  }

  .alert-button-inner {
    background: #FFFFFF;
    border: 2px solid #13284c;
    justify-content: center;
  }

  .alert-button-group {
    justify-content: center;
  }

  .alert-button:active .alert-button-inner {
    background: #13284c;
  }

  .alert-button:active{
    color: #FFFFFF;
  }
  
  .alert-button.x-button:active span{
    color:#FFFFFF;
  }
  
  .alert-button.x-button {
    position: absolute;
    top: 20px;
    right: 23px;
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #13284c;
    width:fit-content;
    --ripple-color:#FFFFFF;
    .alert-button-inner{
    border: none;
  }
  }
}

.my-control-panel-loader-css{
  --backdrop-opacity: 1;
  --background: #ffffff;
  color: #13284c;
  --spinner-color: #13284c;
  z-index: 1 !important;
  top:64px;
  height: calc(100% - 136px);
  
  .loading-wrapper {
    box-shadow: 0 0 5px 1px #13284c;
  }
 
  ion-backdrop{
    display:fixed;
    background:#ffffff;
    z-index: -1;
  }
  
}

.my-control-panel-search-loader-css{
  --backdrop-opacity: 1;
  --background: #ffffff;
  color: #13284c;
  --spinner-color: #13284c;
  z-index: 1 !important;
  
  .loading-wrapper {
    box-shadow: 0 0 5px 1px #13284c;
  }
 
  ion-backdrop{
    height: calc(100% - 56px);
    display:fixed;
    top:64px;
    background:#ffffff;
    z-index: -1;
  }
  
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}
